import React, {
  useState,
  useEffect,
  useContext,
  useRef,
  useCallback,
} from "react";
import Header from "../components/Header";
import { AuthContext } from "../context/Auth";
import useWidgets from "../hooks/useWidgets";
import { ConsultationContext } from "../context/Consultation";
import Footer from "../components/Footer";
import { AnalyticsContext } from "../context/Analytics";

import nasscom from "../staticAssets/nasscom.png";
import webengage from "../staticAssets/webengage.jpeg";
import villageglobal from "../staticAssets/villageglobal.png";
import iseed from "../staticAssets/iseed.jpeg";
import { navigate } from "gatsby";
import Sec1 from "../components/homePage/Sec1";
import Sec2 from "../components/homePage/Sec2";
import Sec3 from "../components/homePage/Sec3";
import Sec4 from "../components/homePage/Sec4";
import Sec5 from "../components/homePage/Sec5";
import Sec6 from "../components/homePage/Sec6";

import gp from "../../src/staticAssets/gp.png";
import ap from "../../src/staticAssets/image 116.png";

const limit = 20;

const IndexPage = () => {
  const [page, setpage] = useState(1);
  const [host, sethost] = useState("");
  const [category, setcategory] = useState("all");
  const [showDownloadBar, setShowDownloadBar] = useState(true);

  const {
    isLoading,
    widgets,
    hasMore,
    setStatus,
  } = useWidgets(category, page, limit, setpage);
  const { user, fetchUser } = useContext(AuthContext) || {
    user: {},
    fetchUser: () => { },
  };
  const { astrologerId } = useContext(ConsultationContext) || {
    astrologerId: "",
  };

  const { gtmPageViewEvent } = useContext(AnalyticsContext) || {
    gtmPageViewEvent: () => { },
  };

  useEffect(() => {
    gtmPageViewEvent(window.location.pathname, window.location.host, user.meesho_userId);
  }, []);

  const observer = useRef();
  const lastElementRef = useCallback(
    (node) => {
      if (isLoading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setpage((prev) => prev + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [isLoading, hasMore]
  );
  useEffect(() => {
    sethost(window.location.host);
    fetchUser();
  }, []);

  return (
    <div className="h-screen flex flex-col hide-scroll-bar scrollbar-hide">
      <Header />
      <div className="flex-1 overflow-y-auto flex flex-col z-0">
        <main className="grow  hide-scroll-bar scrollbar-hide">
          <Sec1 user={user} />
          <Sec2
            widgets={widgets}
            setStatus={setStatus}
            astrologerId={astrologerId}
          />
          <Sec3 />
          <Sec4 />
          <Sec5 user={user} />
          <Sec6 />
          <div className="mt-20">
            <h4 className="text-black text-[25px] md:text-[50px] font-bold playfair text-center">
              Our Supporters
            </h4>
            <div className="flex flex-wrap items-center container mx-auto justify-evenly my-10">
              <img
                loading="lazy"
                className="object-contain h-8 my-4"
                width="300"
                height="100"
                src={nasscom}
                alt="Nasscom"
              />
              <img
                loading="lazy"
                className="object-contain h-24 mt-4"
                width="300"
                height="100"
                src={villageglobal}
                alt="Village Global"
              />
              <img
                loading="lazy"
                className="object-contain h-[3rem] md:h-[3.5rem]"
                src={iseed}
                width="300"
                height="100"
                alt="ISeed"
              />
              <img
                loading="lazy"
                className="object-contain h-12 mt-4 md:mt-0 mb-4"
                width="300"
                height="100"
                src={webengage}
                alt="Webengage"
              />
            </div>
          </div>
          <div className="mt-24 pb-10 bg-gray-50">
            <h2 className="text-black text-[20px] md:text-[40px] font-bold playfair text-center">
              WHY ASTROLOGY?
            </h2>
            <div className="flex flex-wrap items-center container mx-auto justify-evenly my-10">
              <p>Astrology has been an integral part of Indian culture for millennia. It’s not just about predicting the future; it’s a profound science that offers insights into our past, present, and future by studying the movements of celestial bodies. The practice of astrology in India, known as Jyotish, helps people navigate through life's challenges, plan their future, and understand their inner selves better. With the guidance of astrology, you can make informed decisions about relationships, careers, health, and more.
              </p> <br />
              <p>The roots of astrology can be traced back to ancient civilizations. In India, astrology is deeply intertwined with religion and daily life. From choosing an auspicious date for weddings to making important business decisions, astrology plays a vital role. The belief is that the positions of planets and stars at the time of a person’s birth can significantly influence their life’s journey. This celestial roadmap is what astrologers interpret to provide guidance and predictions.
              </p>
              <br />
              <p>Astrology is also about self-discovery. By understanding your birth chart, you can gain insights into your personality, strengths, and weaknesses. It’s like having a personalized guide to navigate through life’s complexities. The stars and planets are like cosmic teachers, offering lessons and guidance to help you grow and evolve.</p>
              {/* <br /> */}
            </div>
            <div className=" flex-wrap  container mx-auto  my-10">
              <h3 className="text-black text-[10px] md:text-[20px] font-bold playfair text-start mb-2">Online Astrology Consultation & Services</h3>
              <p>At Bodhi, we offer a comprehensive range of online astrology consultations and services designed to meet your specific needs. Our platform connects you with the best astrologers from across India, ensuring you receive accurate and reliable guidance.</p>
              <h4 className="text-black text-[15px] md:text-[15px] font-bold  text-start mb-1 mt-2">Love and Relationships</h4>
              <p>Love is one of the most complex and beautiful aspects of life. Our astrologers can help you understand your romantic relationships better. By analyzing your birth chart and that of your partner, they can provide insights into compatibility, potential challenges, and ways to strengthen your bond. Whether you're single, in a relationship, or going through a breakup, astrology can offer valuable guidance.</p>
              <h4 className="text-black text-[15px] md:text-[15px] font-bold  text-start mb-1 mt-2">Career and Finance</h4>
              <p>In today's competitive world, making the right career and financial decisions is crucial. Our astrologers can analyze your birth chart to provide insights into your professional life. They can help you identify suitable career paths, times for job changes, and strategies for financial growth. Whether you're looking for a job, planning to start a business, or seeking financial stability, astrology can be a valuable tool.</p>
              <h4 className="text-black text-[15px] md:text-[15px] font-bold  text-start mb-1 mt-2">Health and Well-being</h4>
              <p>Health is wealth, and astrology can provide guidance on maintaining your well-being. By studying the planetary positions in your birth chart, our astrologers can identify potential health issues and suggest remedies. They can also provide insights into your mental and emotional well-being, helping you achieve a balanced and healthy life.</p>
              <h4 className="text-black text-[15px] md:text-[15px] font-bold  text-start mb-1 mt-2">Personal Growth and Spirituality</h4>
              <p>Astrology is a powerful tool for self-discovery and personal growth. Our astrologers can help you understand your strengths and weaknesses, guiding you on a path of self-improvement. They can also provide insights into your spiritual journey, helping you connect with your higher self and achieve inner peace.</p>
              <h3 className="text-black text-[10px] md:text-[20px] font-bold playfair text-start mb-2 mt-3">Online Astrologer</h3>
              <p>Our team of skilled astrologers is available online to assist you with your queries. With the convenience of online consultations, you can connect with the best astrologers from the comfort of your home.</p>
              <h4 className="text-black text-[15px] md:text-[15px] font-bold  text-start mb-1 mt-2">Specialized Astrologers</h4>
              <p>We have astrologers who specialize in various fields such as love, career, health, and spiritual growth. Whether you need advice on relationships or career guidance, we have the right expert for you.</p>
              <h4 className="text-black text-[15px] md:text-[15px] font-bold text-start mb-1 mt-2">Certified Vedic Astrologers</h4>
              <p>Our astrologers are certified and well-versed in Vedic astrology. They have years of experience and have helped countless individuals with their insights and predictions.</p>
              <h4 className="text-black text-[15px] md:text-[15px] font-bold text-start mb-1 mt-2">Tarot Readers and Numerologists</h4>
              <p>In addition to astrologers, we also have tarot readers and numerologists on board. Tarot cards can provide valuable insights into your current situation and future prospects, while numerology can help you understand the significance of numbers in your life.</p>
              <h3 className="text-black text-[10px] md:text-[20px] font-bold playfair text-start mt-7 mb-2">Stay Updated With Daily Horoscope Predictions & Zodiac Signs
              </h3>
              <p>Never miss out on important updates with our daily horoscope predictions. Each zodiac sign has unique characteristics and challenges. By staying updated, you can:
              </p>
              <h4 className="text-black text-[15px] md:text-[15px] font-bold mb-1">Plan Your Day Better</h4>
              <p>Knowing what the stars have in store for you can help you plan your day more effectively. Whether it's a good day for making important decisions or a time to lie low, your daily horoscope can provide valuable guidance.</p>
              <h4 className="text-black text-[15px] md:text-[15px] font-bold mb-1 mt-2">Gain Insights into Zodiac Signs</h4>
              <p>Understanding the characteristics of different zodiac signs can help you navigate your relationships better. Whether it's dealing with a Leo's fiery nature or a Virgo's perfectionism, knowing these traits can make your interactions smoother.</p>
              <h4 className="text-black text-[15px] md:text-[15px] font-bold mb-1 mt-2">Make Informed Decisions</h4>
              <p>Planetary movements can influence various aspects of your life. By staying updated with your horoscope, you can make informed decisions about your personal and professional life.</p>
            </div>
     
            <div className="container mx-auto  my-10">
              <h3 className="text-black text-[10px] md:text-[20px] font-bold playfair text-start mb-2">Why Choose Our Astrology Experts?</h3>
              <p>Choosing the right astrologer is crucial for accurate and reliable guidance. Here’s why our astrology experts stand out:</p>
              <h4 className="text-black text-[15px] md:text-[15px] font-bold mb-1 mt-2">Expertise</h4>
              <p>Our astrologers are well-versed in Vedic astrology and have decades of experience. They have studied the ancient texts and have a deep understanding of the science of astrology. This expertise ensures that you receive accurate and insightful guidance.</p>
              <h4 className="text-black text-[15px] md:text-[15px] font-bold mb-1 mt-2">Accuracy</h4>
              <p>We pride ourselves on providing precise and reliable predictions. Our astrologers use advanced techniques and tools to analyze your birth chart and provide accurate insights.</p>
              <h4 className="text-black text-[15px] md:text-[15px] font-bold mb-1 mt-2">Confidentiality</h4>
              <p>Your personal information is safe with us. We value your privacy and ensure that your details are kept confidential. You can trust us to handle your information with the utmost care.</p>
              <h4 className="text-black text-[15px] md:text-[15px] font-bold mb-1 mt-2">Availability</h4>
              <p>We understand that life can be unpredictable, and you might need guidance at any time. That's why our astrologers are available round the clock. You can connect with them whenever you need, without any restrictions.</p>
              <h2 className="text-black text-[10px] md:text-[20px] font-bold playfair text-start mt-7 mb-2">FAQ'S about Astrology</h2>
              <h3 className="text-black text-[15px] md:text-[15px] font-bold  mt-2">Why Is Astrology So Accurate?               </h3>
              <p>Astrology is based on meticulous calculations of planetary positions and their influence on human life. This ancient science has been practiced and refined over thousands of years, making it highly accurate when performed by skilled astrologers. The accuracy of astrology lies in its detailed analysis of the birth chart, which considers the positions of planets at the exact time and place of your birth.</p>
              <h3 className="text-black text-[15px] md:text-[15px] font-bold mt-2">Why Should You Choose Bodhi For An Astrology Horoscope?</h3>
              <p>Bodhi combines traditional astrological wisdom with modern technology. Our user-friendly app and experienced astrologers ensure you get the best possible guidance. Our platform offers personalized horoscopes that are tailored to your specific needs and circumstances. This personalized approach ensures that you receive relevant and accurate insights.</p>
              <h3 className="text-black text-[15px] md:text-[15px] font-bold  mt-2">Is Astrology Prediction True?</h3>
              <p>Astrology predictions are based on the alignment and movement of celestial bodies. While it's not an exact science, many people find that astrology provides valuable insights and helps them navigate life's uncertainties. The predictions are based on the patterns observed in the planetary movements and their impact on human life. While they may not be 100% accurate, they provide a general direction and guidance.</p>
              <h3 className="text-black text-[15px] md:text-[15px] font-bold mt-2">How Can Online Astrology Help Me In Predicting The Future?</h3>
              <p>Online astrology consultations bring the wisdom of astrologers right to your fingertips, offering the convenience of getting insights from the comfort of your home. Astrology, particularly Indian astrology, uses the precise positions of celestial bodies at the time of your birth to create a natal chart or Kundli. This chart serves as a blueprint of your life, highlighting your strengths, weaknesses, and potential future events.
              </p>
              <br />
              <p>Astrologers analyze your birth chart by studying the positions and movements of planets, houses, and zodiac signs. Each planet and house represents different aspects of life. For example, the sun signifies your core identity and vitality, while the moon relates to emotions and the subconscious mind. By interpreting these elements, astrologers can provide predictions about your future, such as upcoming opportunities or challenges in areas like career, relationships, and health.
              </p>
              <br />
              <p>Moreover, online astrology consultations offer the advantage of real-time interaction with professional astrologers. You can ask questions, seek clarifications, and receive personalized advice. These consultations often include remedies to mitigate negative influences and enhance positive ones. Remedies can range from wearing specific gemstones, performing rituals, or chanting mantras tailored to your unique chart.
              </p><br />
              <p>Online platforms like Bodhi make this process seamless. You can book sessions with experienced astrologers, access detailed reports, and even get regular updates about significant astrological events. This holistic approach helps you navigate life's uncertainties with greater confidence and preparedness.
              </p>
              <h3 className="text-black text-[15px] md:text-[15px] font-bold mb-1 mt-2">What Kind Of Questions Can I Ask To Online Astrologers?</h3>
              <p>One of the biggest advantages of online astrology consultations is the ability to ask a wide range of questions. Whether you're looking for guidance on love and relationships, career choices, financial decisions, or personal growth, astrologers can offer valuable insights.
              </p>
              <p>Here are some common questions people ask:
              </p>
              <ul style={{ listStyleType: 'disc', marginLeft: '20px' }}>
                <ul><strong>Love and Relationships:</strong>
                  <ul style={{ listStyleType: 'disc', marginLeft: '20px' }}>
                    <li>Is this person my soulmate?</li>
                    <li>When will I get married?</li>
                    <li>How can I improve my relationship with my partner?</li>
                  </ul>
                </ul>

                <ul><strong>Career and Finance:</strong>
                  <ul style={{ listStyleType: 'disc', marginLeft: '20px' }}>
                    <li>What career path should I choose?</li>
                    <li>When will I get a promotion?</li>
                    <li>How can I enhance my financial stability?</li>
                  </ul>
                </ul>

                <ul><strong>Health:</strong>
                  <ul style={{ listStyleType: 'disc', marginLeft: '20px' }}>
                    <li>Are there any upcoming health concerns I should be aware of?</li>
                    <li>What can I do to improve my well-being?</li>
                  </ul>
                </ul>

                <ul><strong>Personal Growth:</strong>
                  <ul style={{ listStyleType: 'disc', marginLeft: '20px' }}>
                    <li>What are my strengths and weaknesses?</li>
                    <li>How can I achieve my goals and fulfill my potential?</li>
                  </ul>
                </ul>

                <ul><strong>General Guidance:</strong>
                  <ul style={{ listStyleType: 'disc', marginLeft: '20px' }}>
                    <li>What does my future hold?</li>
                    <li>Are there any major changes coming in my life?</li>
                    <li>How can I overcome the challenges I'm currently facing?</li>
                  </ul>
                </ul>
              </ul>
              <p className="mt-4">Astrologers use your birth chart to provide answers tailored to your unique circumstances. This personalized approach ensures that the guidance you receive is relevant and actionable, helping you make informed decisions and plan for the future.
              </p>

              <h3 className="text-black text-[15px] md:text-[15px] font-bold mb-1 mt-2">What Is The Duration For Online Astrology Consultation?
              </h3>
              <p>Astrology has no limits, and neither do we. We have numerous astrologers on board whom you can connect with at any time. They are available to help you with all your queries without any time limit intact.
              </p>
              <p className="mt-2">Online astrology consultations can vary in duration based on your needs and the complexity of your questions. At Bodhi, we ensure that our astrologers are available to cater to your needs, offering flexibility and convenience. Whether you prefer a quick session or a detailed consultation, you can easily book appointments through our platform and receive the guidance you need.
              </p>
              <h3 className="text-black text-[15px] md:text-[15px] font-bold mb-1 mt-2">Can Astrology Predictions Be Changed?</h3>
              <p>Astrology provides guidance and foresight, but it doesn't determine your fate. The predictions offered by astrologers are based on the analysis of celestial influences, but they are not set in stone. You have the power to change your actions and decisions, which can alter the course of your life.
              </p>
              <br />
              <p>While astrology can highlight potential opportunities and challenges, it is ultimately up to you to take action. For instance, if an astrologer predicts a favorable period for career advancement, you need to put in the effort to seize those opportunities. Similarly, if you are warned about potential health issues, taking preventive measures can mitigate those risks.
              </p>
              <br />
              <p>Astrology also offers remedies to enhance positive influences and minimize negative ones. These remedies, such as wearing specific gemstones or performing rituals, are designed to balance the planetary energies in your favor. By following these recommendations and making conscious choices, you can influence the outcome of events and steer your life in a positive direction.
              </p>

              <h3 className="text-black text-[15px] md:text-[15px] font-bold mb-1 mt-2">Can Astrology Predict About Lottery Winning?</h3>
              <p>Astrology can provide insights into favorable times for financial gains, but predicting lottery wins is not something it specializes in. The lottery is largely based on chance, and while astrology can highlight periods of financial potential, it cannot guarantee lottery success.
              </p><br />
              <p>However, astrology can guide you in making informed financial decisions and identifying periods when you might be more inclined to attract wealth. By understanding the planetary influences on your financial house, you can make strategic investments, seek new opportunities, and enhance your financial stability.
              </p>
              <h3 className="text-black text-[15px] md:text-[15px] font-bold mb-1 mt-2">What Benefits Can I Expect If I Avail Astrology Services?
              </h3>
              <p>With our astrology services, you can gain clarity, confidence, and a better understanding of your life's direction.  Here are some of the key benefits you can expect:
              </p>
              <h4 className="text-black text-[15px] md:text-[15px] font-bold  mt-1">Clarity and Insight:
              </h4><p>Astrology helps you gain a deeper understanding of yourself, your strengths, and your weaknesses. This self-awareness can guide you in making informed decisions and achieving personal growth.
              </p>
              <h4 className="text-black text-[15px] md:text-[15px] font-bold mt-1">Guidance and Support:</h4>
              <p>Astrologers provide personalized advice and remedies to help you navigate challenges and make the most of opportunities. Whether you're facing a tough decision or seeking direction, astrology offers valuable support.
              </p>
              <h4 className="text-black text-[15px] md:text-[15px] font-bold mt-1">Peace of Mind:</h4>
              <p>Knowing what the future holds can bring peace of mind and reduce anxiety. Astrology offers a sense of reassurance and helps you feel more prepared for life's uncertainties.
              </p>
              <h4 className="text-black text-[15px] md:text-[15px] font-bold  mt-1">Empowerment:</h4>
              <p>Astrology empowers you to take control of your life. By understanding the influences of celestial bodies, you can make proactive choices and shape your destiny.
              </p>
              <h4 className="text-black text-[15px] md:text-[15px] font-bold  mt-1">Holistic Approach:
              </h4>
              <p>Our astrology services consider all aspects of your life, from relationships and career to health and personal growth. This holistic approach ensures comprehensive guidance and support.
              By availing our astrology services, you can expect to enhance your overall well-being, make better decisions, and achieve a balanced and fulfilling life.
              </p>
              <h3 className="text-black text-[15px] md:text-[15px] font-bold  mt-1">Which App Is Best For Kundli?
              </h3>
              <p>Our app combines traditional astrological wisdom with modern technology to offer precise and reliable Kundli readings.
              <br />With the Bodhi app, you can:
              </p>
              <strong> Consult with Astrologers:</strong> Connect with experienced astrologers for personalized guidance and remedies.
             <br /> <strong>Stay Updated:</strong> Get regular updates on significant astrological events and their potential impact on your life.
             <p>The Bodhi app is user-friendly and designed to provide accurate and insightful astrological information, making it a reliable choice for Kundli readings.
             </p>
             <h3 className="text-black text-[15px] md:text-[15px] font-bold mb-1 mt-2">How Reliable Is The Bodhi App?</h3>
             <p>Our app is designed by experts and provides highly accurate and reliable astrological information.</p>

             <p>At Bodhi, we prioritize the quality and accuracy of our services. Our team of experienced astrologers review and validate the information provided by the app, ensuring that you receive trustworthy guidance.
             </p>
     
             <p>Additionally, our app is regularly updated to incorporate the latest astrological knowledge and practices. We also prioritize user feedback and continually improve our services to meet your needs.
             </p>
             <h3 className="text-black text-[15px] md:text-[15px] font-bold mb-1 mt-2">Can I Trust Astrology Apps?</h3>
             <p>While not all astrology apps are trustworthy, Bodhi stands out due to our commitment to accuracy, confidentiality, and user satisfaction. 
             </p>
             <p>Here's why you can trust the Bodhi app:
             </p>
<br />
             <p> <strong>Expertise:</strong>  Our app is backed by a team of experienced astrologers who ensure the accuracy and reliability of the information provided.
             </p>
             <p> <strong>Confidentiality:</strong> We prioritize your privacy and ensure that your personal information is kept secure.
             </p>
             <p> <strong>User Satisfaction:</strong>  We are dedicated to providing a user-friendly experience and continuously improving our services based on user feedback.
             </p>
            </div>
          </div>
          <button
            className={`justify-center text-white fixed right-2 ${showDownloadBar ? "bottom-16" : "bottom-2"} lg:bottom-2 rounded-t-2xl but px-4 py-2 flex gap-1 shadow-xl`}
            onClick={() => {
              navigate("/consultAstro");
            }}
          >
            {user.name !== undefined
              ? `Consult Top Astrologers Now!`
              : `Login & get ${user?.currencyType == "usd" ? "$ 4" : "₹ 91"
              } in your account!`}
          </button>
          {showDownloadBar && <div className="lg:hidden fixed right-0 bottom-0 w-screen bg-black h-14 bg-opacity-95 flex items-center justify-evenly">
            <a
              href="https://bit.ly/BodhiWeb"
              aria-label="Google Play Store Link"
            >
              <img
                loading="lazy"
                src={gp}
                width="200"
                height="100"
                className="h-[60px] object-contain py-2"
                alt="Google Play Store Image"
              />
            </a>
            <a
              href="https://bit.ly/BodhiWeb"
              aria-label="App Store Link"
            >
              <img
                loading="lazy"
                src={ap}
                width="160"
                height="100"
                className="h-[60px] object-contain py-2"
                alt="App Store Image"
              />
            </a>
            <button
              className="text-white pr-2"
              onClick={() => {
                setShowDownloadBar(false);
              }}
            >
              X
            </button>
          </div>}
        </main>

        {host != "" &&
          (host != "astroguru.bodhiness.com" ||
            host != "mobafl.bodhiness.com" ||
            host != "webj.bodhiness.com" ||
            host != "lokal.bodhiness.com" ||
            host != "lokal.bodhiness.com" ||
            host != "jio.bodhiness.com" ||
            host != "meesho.bodhiness.com") && <Footer />}
      </div>
    </div>
  );
};

export default IndexPage;
